const translation = {
    pageName: 'チャットフロー',
    table: {
        header: {
            name: '名前',
            nodes: 'ノード',
            lastModified: '最終更新日',
            action: 'アクション'
        },
        data: {
            notFound: 'まだチャットフローがありません'
        }
    },
    form: {
        add: {
            placeholder: '無題のチャットフロー'
        },
        search: {
            placeholder: '名前またはカテゴリで検索'
        },
        conversationLog: {
            title: '会話ログ',
            data: {
                notFound: 'メッセージが見つかりません'
            }
        },
        dialogueDetail: {
            title: 'ダイアログ詳細',
            data: {
                notFound: 'セッションが見つかりません'
            }
        },
        dialogueLog: {
            title: 'ダイアログログ',
            data: {
                notFound: 'レコードが見つかりません'
            }
        },
        variableDetail: {
            title: '変数の値の推移'
        },
        variableLog: {
            title: '変数ログ',
            data: {
                notFound: '変数が見つかりません'
            }
        },
        apiDialog: {
            title: 'ウェブサイトに埋め込むかAPIとして使用'
        },
        saveChatFlow: {
            title: '新しいチャットフローを保存',
            field: {
                serviceAccount: 'サービスアカウント',
                projectName: 'プロジェクト名'
            },
            placeholder: {
                projectName: '新しいプロジェクト'
            }
        },
        deleteChatFlow: {
            title: '削除',
            description: 'チャットフロー {{name}} を削除しますか？'
        },
        renameChatFlow: {
            title: 'チャットフローの名前を変更'
        },
        starterMessageDialog: {
            title: '会話の開始メッセージ',
            description: '会話の最初に表示される開始メッセージ'
        },
        starterPrompt: {
            title: '会話の開始プロンプト',
            description: 'チャットにメッセージがない場合にのみ開始プロンプトが表示されます'
        },
        chat: {
            title: 'チャット履歴をクリア',
            description: 'すべてのチャット履歴をクリアしてもよろしいですか？'
        },
        detailLog: {
            title: '会話ログの詳細'
        },
        conversationVariable: {
            title: '会話の変数ログ'
        },
        chunkList: {
            title: 'チャンクのリスト',
            data: {
                notFound: 'チャンクが見つかりません'
            }
        },
        chunkDetail: {
            title: 'チャンクの詳細',
            data: {
                notFound: 'チャンクの詳細が見つかりません'
            }
        },
        importChatFlow: {
            title: 'チャットフローのインポート',
            description: 'ここにファイルをドラッグするか、クリックしてアップロードしてください',
            note: {
                acceptableFile: 'チャットフローに適したスキームのファイル形式のみ受け付けます',
                numberOfTime: '1回に1つのファイルのみインポート可能です'
            }
        }
    },
    button: {
        saveChanges: '変更を保存',
        variablesLog: '変数ログ',
        downloadAll: 'すべてダウンロード',
        download: 'ダウンロード',
        checkVariables: '変数を確認',
        conversationLog: '会話ログ',
        detail: '詳細',
        editName: '名前を編集',
        saveName: '名前を保存',
        apiEndPoint: 'APIエンドポイント',
        saveChatFlow: 'チャットフローを保存',
        settings: '設定',
        rename: '名前を変更',
        clearChat: 'チャットをクリア',
        addParameters: '追加パラメーター',
        addNodes: 'ノードを追加'
    },
    action: {
        addNewKey: '新しいキーを追加',
        openNewTab: '新しいタブで開く',
        makePublic: '公開する',
        copy: 'コピー完了！',
        chat: 'チャット',
        clearChatHistory: 'チャット履歴をクリア',
        expandChat: 'チャットを拡大',
        starterMessage: '開始メッセージ',
        deleteChatflow: 'チャットフローを削除',
        searchNodes: 'ノードを検索'
    },
    message: {
        rateLimitError: 'レート制限の入力フィールドをすべて埋める必要があります',
        savedConfigSuccess: 'API設定を保存しました',
        savedConfigFailed: 'API設定の保存に失敗しました: {{error}}',
        savedChatBotConfigSuccess: 'チャットボット設定を保存しました',
        savedChatBotConfigFailed: 'チャットボット設定の保存に失敗しました: {{error}}',
        savedStarterMessageSuccess: '会話の開始メッセージを保存しました',
        savedStarterMessageFailed: '会話の開始メッセージの保存に失敗しました: {{error}}',
        savedStarterPromptSuccess: '会話の開始プロンプトを保存しました',
        savedStarterPromptFailed: '会話の開始プロンプトの保存に失敗しました: {{error}}',
        savedChatflowSuccess: 'チャットフローを保存しました',
        savedChatflowFailed: 'チャットフローの保存に失敗しました: {{error}}',
        deletedChatflowSuccess: 'チャットフローの削除に成功しました',
        getChatflowFailed: 'チャットフローの取得に失敗しました: {{error}}',
        testChatFlowFailed: 'チャットフローのテストに失敗しました',
        clearedChatSuccess: 'すべてのチャット履歴を正常にクリアしました',
        importChatFlowSuccess: 'チャットフローを正常にインポートしました',
        importChatFlowFailed: 'チャットフローのインポートに失敗しました',
        importProcessing: 'チャットフローをインポート中です。数分お待ちください',
        unSaved: '保存されていない変更があります。このまま移動してもよろしいですか？'
    },
    text: {
        paste: 'HTMLファイルの{{data}}タグのどこにでもこれを貼り付けてください。',
        specific: 'また、次の項目を指定することもできます',
        version: 'バージョン',
        botMessage: 'ボットメッセージ',
        userMessage: 'ユーザーメッセージ',
        textInput: 'テキスト入力',
        sessionMemory: 'セッションメモリ',
        more: 'さらに表示',
        userId: 'ユーザーID：',
        createdDate: '作成日:',
        lastUpdated: '最終更新日：',
        time: '時間',
        dialogueLog: '会話ログ',
        waitingResponse: '応答を待っています...',
        typeQuestion: '質問を入力してください...',
        inputs: '入力',
        output: '出力',
        stream: 'ストリーム',
        session: 'セッション',
        clearSearch: '検索をクリア',
        chainName: 'チェーン名:',
        searchQuery: '検索クエリ:',
        chunks: 'チャンク:',
        currentStatus: '現在のステータス:',
        variableName: '変数名',
        value: '値',
        score: 'スコア:',
        sourceType: 'ソースタイプ',
        note: '注意:'
    },
    variables: {
        userMessage: 'ユーザー',
        apiMessage: 'AI'
    },
    node: {
        form: {
            editTool: {
                title: 'ツールを編集'
            },
            editAssistant: {
                title: 'アシスタントを編集'
            },
            addNewTool: {
                title: '新しいツールを追加'
            },
            addNewAssistant: {
                title: '新しいアシスタントを追加'
            },
            deleteKeyExtract: {
                confirm: 'このキー抽出を削除してもよろしいですか？'
            }
        },
        regexTable: {
            title: 'キー抽出',
            header: {
                no: '番号',
                defaultValue: 'デフォルト値',
                variableName: '変数名',
                action: '操作'
            }
        },
        output: {
            label: '初期出力',
            placeholder: '初期出力'
        },
        category: {
            Chains: 'チェーン',
            'Chat Models': 'チャットモデル',
            'Document Loaders': 'ドキュメントローダー',
            Embeddings: '埋め込み',
            Prompts: 'プロンプト',
            'Text Splitters': 'テキストスプリッター',
            Utilities: 'ユーティリティ',
            'Vector Stores': 'ベクターストア',
            NEW: '新規'
        }
    },
    validation: {
        serviceAccount: {
            required: 'サービスアカウントは必須です'
        },
        chatflowName: {
            required: 'プロジェクト名は必須です'
        },
        importChatFlow: {
            fileType: 'JSONファイルのみ受け付けます',
            wrongSchema: 'チャットフローのスキーマが間違っています',
            noContent: '内容のないファイルはアップロードできません'
        }
    }
}

export default translation
